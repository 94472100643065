<template>
  <div>
    <h1>{{ t("ecommerce.checkout.index.browserTitle") }}</h1>
    <form-button @click="createOrder">Create Order</form-button>
    // todo: save order correctly - do not actually associate models, encode their states instead

    <br>
    <pre class="ltr">
      {{testData}}
    </pre>
  <debug v-model="order"></debug>

  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { reactive } from "vue";
import useCarts from "@/client/extensions/composition/useCarts.js";

export default {
  layout: "default",

  props: {
    cartId: {
      type: [String, Array],
      default: false,
    },
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);

    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data: function () {
    return {
      cart: false,
      order: false,

    };
  },
  meta: "ecommerce.checkout.index.browserTitle",
  async created() {
    this.cart = await this.carts.getCart();
    console.log("checkout cart", this.cart);
  },
  computed: {
    safeCart:{
      get() {
        if (this.cart) {
          return this.cart;
        }

        return {};
      },
      set(arg) {
        this.cart = arg;
      }
    }
  },
  methods: {
    async createOrder() {

      let profile = this.$store.getters["user/profile"];
      const orderData = {
        UserId: profile?.id,
        OwnerId: profile?.id,
        CartUuid: this.cart.uuid,
        buyer: profile ? profile : {},
        shipping: {
          method: "test",
          address: { todo: "address module states" },
        },
      };

      const result = await this.asyncOps.asyncCall("ecommerce/order", orderData, {
        method: "post",
      });


      if (! result.hasError) {
        this.order = result.data.item;
      }

      let newCart = await this.carts.createNewDefaultCart();
     // this.carts.forgetCart(newCart);
    },
  },
};
</script>

<style scoped lang="scss"></style>
